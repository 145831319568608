<template>
  <div class="nav-profile">
    <ul class="navbar-nav navbar-right navbar-expand">
      <li class="nav-item">
        <global-search-button></global-search-button>
      </li>
      <li class="nav-item" v-if="showGlobalSearch">
        <a
          id="cm-global-search"
          class="nav-link pr-4 pl-4"
          href="javascript:void(0)"
          aria-label="cm global search"
        >
          <s-icon
            icon-name="search"
            icon-color="#fff"
            width="24"
            height="24"
          ></s-icon>
        </a>
      </li>
      <li
        class="nav-item toggle-side-bar-icon"
        v-if="$store.getters.isShowMobileMenu"
      >
        <a
          class="nav-link pr-4 pl-4"
          :aria-label="$ts('user_info')"
          href="javascript:void(0)"
          @click="
            $store.commit('setMobileMenuStatus', !$store.state.mobileMenuStatus)
          "
          @blur="$store.commit('setMobileMenuStatus', false)"
        >
          <s-icon
            width="24"
            height="24"
            v-show="!$store.state.mobileMenuStatus"
            icon-name="menu"
          >
          </s-icon>
          <s-icon
            icon-name="cancel"
            width="24"
            height="24"
            v-show="$store.state.mobileMenuStatus"
          ></s-icon>
        </a>
      </li>
      <li class="nav-item" v-if="actions && actions.length > 0">
        <s-dropdown>
          <a
            class="nav-link dropdown-toggle pr-4 pl-4"
            href="javascript:void(0)"
            role="button"
            aria-label="dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="add-some-wrap">
              <!-- add some -->
              <s-icon width="24" height="24" icon-name="add"></s-icon>
            </span>
          </a>
          <template v-slot:dropdown>
            <s-dropdown-menu>
              <s-dropdown-item
                v-for="action in actions"
                :key="action.nemuId"
                @click="routeTo(action.path)"
                >{{ action.displayName || action.name }}</s-dropdown-item
              >
            </s-dropdown-menu>
          </template>
        </s-dropdown>
      </li>
      <li class="nav-item user-dropdown">
        <s-dropdown>
          <a
            class="nav-link dropdown-toggle pr-4 pl-4"
            href="javascript:void(0)"
            role="button"
            aria-label="User Info"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <s-icon icon-name="user" width="28" height="28"></s-icon>
            <s-icon icon-name="arrow-bottom"></s-icon>
          </a>
          <template v-slot:dropdown>
            <s-dropdown-menu>
              <li class="user-info-email s-select-dropdown-item">
                <s-icon icon-name="user" width="64" height="64"></s-icon>
                <h4>
                  {{ userInfo.firstName }}
                  {{ userInfo.middleName }}
                  {{ userInfo.lastName }}
                </h4>
                <p class="email-text">
                  {{ userInfo.email || userInfo.username }}
                </p>
              </li>
              <s-dropdown-item
                v-if="showSwitchAccount"
                class="log-out mt-8"
                @click="linkToLandingPage"
              >
                {{ $ts('switch_account') }}
              </s-dropdown-item>
              <s-dropdown-item
                v-if="hasAuthed('updateMyPassword.general')"
                @click="routeTo('/settings')"
              >
                {{ $ts('settings') }}
              </s-dropdown-item>
              <s-dropdown-item
                @click="showSwitch = true"
                v-if="switchProfileStatus"
              >
                {{ $ts('switch_profile') }}
              </s-dropdown-item>
              <s-dropdown-item class="log-out mt-8" @click="logout">
                {{ $ts('log_out') }}
              </s-dropdown-item>
            </s-dropdown-menu>
          </template>
        </s-dropdown>
      </li>
    </ul>
    <s-modal
      size="xl"
      :title="$t('profile.switch_profile')"
      :visible.sync="showSwitch"
      v-if="showSwitch"
    >
      <switch-profile></switch-profile>
    </s-modal>
  </div>
</template>
<script>
import SwitchProfile from './SwitchProfile.vue'
import GlobalSearchButton from './global-search/global-search-button.vue'

const LOGOUT_URL = '/auth/logout'

export function logout(vue) {
  const { $utils } = vue
  $utils.clearLocalStorage(['rememberMe', 'applicationLocalStorage'])
  $utils.clearSessionStorage()

  if (vue.$dev) {
    Object.entries($utils.cookie.get()).forEach(([key]) => {
      $utils.cookie.remove(key)
      vue.$nextTick(() => {
        window.location.href = '/auth/login'
      })
    })
  } else {
    vue.$nextTick(() => {
      window.location.href = LOGOUT_URL
    })
  }
}

export default {
  components: { SwitchProfile, GlobalSearchButton },
  data() {
    return {
      showSwitch: false,
      switchProfileStatus: true,
    }
  },
  created() {
    this.$http.get('/auth/profiles').then(({ data }) => {
      this.switchProfileStatus = data && data.items && data.items.length > 1
    })
  },
  computed: {
    showSwitchAccount() {
      const userType = {
        lender: 'LENDER',
        investment: 'INVESTMENT',
        customer: 'CUSTOMER',
      }
      const isCustomer =
        userType.customer === this.$store.state.userInfo?.profile?.roleType
      const menuList =
        this.$store?.state?.userInfo?.roleConfiguration?.menuList || []
      const menusIncludeMyLine = menuList.some(
        (item) => item.menuCode === 'MY_LINE'
      )
      return isCustomer && menusIncludeMyLine
    },
    actions() {
      const state = this.$store.state
      const headerData = state?.userInfo?.roleConfiguration?.menuList || []
      return headerData.filter((v) => v.type === 'action')
    },
    sidebarData() {
      return this.$store.state.sidebarData
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    showGlobalSearch() {
      const { path } = this.$route
      const isCmRoute = path.indexOf('/collateral-management') >= 0
      return this.hasAuthed('showGlobalSearch') && isCmRoute
    },
  },
  methods: {
    logout() {
      logout(this)
    },
    linkToLandingPage() {
      this.$router.push({
        path: '/smp/landing-page',
      })
    },
    routeTo(path) {
      this.$router.push({
        path: path,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './nav.scss';
</style>
