<template>
  <div class="d-flex flex-column portal-home" role="main">
    <a
      class="skippy navbar-bg-color"
      href="#portal-app-content"
      @focus="skippy = true"
      @blur="skippy = false"
    >
      <span>Skip to main content</span>
    </a>
    <header-bar
      :style="{ top: skippy ? '56px' : '0' }"
      @refresh="refresh++"
      @getHeaderNavbarHeight="headerNavbarHeight = $event"
    ></header-bar>
    <global-search-modal
      v-show="$store.state.IS_SHOW_GLOBAL_SEARCH"
    ></global-search-modal>
    <div
      class="d-flex"
      id="portal-app-wrap"
      :style="{ 'margin-top': `${headerNavbarHeight}px` }"
    >
      <sidebar
        :class="['side-bar', { show: $store.state.toggleSideBarStatus }]"
        :headerNavbarHeight="headerNavbarHeight"
      ></sidebar>
      <main
        id="portal-app-content"
        ref="portal-app-content"
        class="flex-grow-1 flex-shrink-1"
      >
        <div
          :style="{ 'min-height': `calc(100vh - ${paddingHeight + 56}px)` }"
          :class="{ 'pt-16': !settings.companyTitle }"
        >
          <s-container v-if="settings.companyTitle" fluid>
            <div class="branding-wrap d-flex align-items-center">
              <h2
                class="theme-text-500 fs-18"
                v-html="sanitizeCompanyTitle"
              ></h2>
              <div
                class="branding-logo-wrap ml-8"
                v-if="!$utils.isEmpty(brandingImg)"
              >
                <img :src="brandingImg" :alt="logoAlt" />
              </div>
            </div>
          </s-container>
          <router-view :key="refresh" />
        </div>
        <footer-content :height.sync="paddingHeight"></footer-content>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderBar from './components/header'
import FooterContent from './components/footer'
import Sidebar from './components/Sidebar.vue'
import GlobalSearchModal from './components/header/global-search/global-search-modal.vue'

export default {
  components: { HeaderBar, FooterContent, Sidebar, GlobalSearchModal },
  data() {
    return {
      refresh: 0,
      paddingHeight: 0,
      settings: this.$store.state.frontendConfig.settings,
      skippy: false,
      headerNavbarHeight: 56,
    }
  },
  watch: {
    $route() {
      this.$refs['portal-app-content'].scrollTop = 0
    },
  },
  computed: {
    logoAlt() {
      if (this.settings.i18n && this.settings.i18n.website_logo_alt) {
        return this.settings.i18n.website_logo_alt
      }
      return this.settings.companyTitle || 'logo'
    },
    hasSidebar() {
      return !!this.$route.meta.sidebar
    },
    sanitizeCompanyTitle() {
      return this.$utils.sanitizeHtml(this.settings.companyTitle)
    },
    brandingImg() {
      const { websiteBrandingPageLogo = '' } = this.settings?.images || {}
      const lastKey = websiteBrandingPageLogo.lastIndexOf('/')
      const imgLn =
        lastKey > 0 ? websiteBrandingPageLogo.substring(lastKey + 1).length : 0
      return imgLn ? websiteBrandingPageLogo : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.application-main {
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
.skippy {
  display: block;
  padding: 1em;
  color: #fff;
  text-align: center;
  background-color: $theme-blue;
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  &:focus {
    width: 100%;
    height: 56px;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: solid 1px $white;
    position: fixed;
    top: 0;
    z-index: $zindex-sticky + 1;
    outline: none !important;
  }
  & span {
    border: dotted 1px #fff;
  }
}

#portal-app-content {
  overflow: auto;
}

.branding-wrap {
  .branding-logo-wrap {
    width: 100px;
    height: 20px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@include media-breakpoint-down(md) {
  .side-bar {
    width: 0;
    z-index: 9;
    // :deep( .sidebar-menu ){
    //   transform: translateX(-100%);
    //   transition: all 0.3s ease-in-out;
    // }
    // &.show :deep( ){
    //   .sidebar-menu {
    //     transform: translateX(0%);
    //     background-color: $white;
    //   }
    // }
  }
}
</style>
